@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.font-montserrat {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #ffe4dd;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #FC4414;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.animate-shimmer {
    animation: shimmer 1.5s infinite linear;
}
.truncate-2-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 3rem;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.animate-pulse {
    background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
    background-size: 2000px 100%;
    animation: shimmer 1.5s infinite linear;
}

/* customCalendarStyles.css */
.rbc-month-view .rbc-day-bg {
    position: relative;
}

.rbc-month-view .rbc-day-bg .custom-day-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.rbc-month-view .rbc-day-bg .rbc-event {
    position: relative;
    z-index: 1;
    background: transparent;
    border: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 0.8s linear infinite;
}

.loader {
    width: 64px;
    height: 48px;
    position: relative;
    animation: split 1s ease-in infinite alternate;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    left: 0;
    top: 0;
    transform: translateX(-10px);
    background: #FF9700;
    opacity: 0.75;
    backdrop-filter: blur(20px);
}

.loader::after {
    left: auto;
    right: 0;
    background: #99780b;
    transform: translateX(10px);
}

@keyframes split {

    0%,
    25% {
        width: 64px
    }

    100% {
        width: 148px
    }
}